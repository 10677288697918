<template>
  <div>
    <c-logo />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="thanks_text"
      v-html="thanks_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title>Vielen Dank!</c-title>

      <p class="mt-5">
        Ihr Antrag ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung inklusive einer Kopie des Antrags per E-Mail. Wenn Sie diese nicht innerhalb weniger Minuten erhalten, überprüfen Sie bitte Ihren Spam-Ordner und wenden sich ggf.
        <a
          href="mailto:atelierstipendium@frappant.org"
          target="_blank"
        >per E-Mail </a>
        an Bianca Müllner.
      </p>
    </div>
  </div>
</template>

<script>
import CLogo from '../components/CLogo.vue'
import CTitle from '../components/CTitle.vue'

export default {
  name: 'VThankYou',

  components: {
    CLogo,
    CTitle
  },

  computed: {
    thanks_text () {
      return this.$store.state.textsInfo.thanks_text
    }
  }
}
</script>
